import React from "react";
import {
  useQueryParam,
  StringParam,
  NumberParam,
  BooleanParam,
} from "use-query-params";

import { Scene } from "../../layout/about";
import { PAGES } from "../../core/constants";

const AU = () => {
  const [merchantId] = useQueryParam("merchantId", StringParam);
  const [paymentOption] = useQueryParam("paymentOption", StringParam);
  const [price] = useQueryParam("price", NumberParam);
  const [promotionMonths] = useQueryParam("promotionMonths", NumberParam);
  const [allowPriceBreakdown] = useQueryParam("ppm", BooleanParam);
  const [productName] = useQueryParam("productName", StringParam);
  const [location] = useQueryParam("location", StringParam);

  const order = {
    productName: productName,
    price: price,
    paymentOption: paymentOption,
    promotionMonths: promotionMonths,
    ppm: allowPriceBreakdown,
    location: location,
  };
  return <Scene au merchantId={merchantId} page={PAGES.About} order={order} />;
};

export default AU;
